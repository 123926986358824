.input {
  padding: 0;
  margin-top: 0;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 4px;
  border-bottom: 1.5px solid var(--light-gray-button-back);
  font-weight: 300;
  &:hover, &:active, &:focus{
    border-bottom-color: var(--main-semi-light-color);
  }
}
.textarea {
  border: 1px solid var(--light-gray-button-back) !important;
  border: 1px;
  padding: 10px;
  margin-top: 40px !important;
  margin-bottom: 29px;
  border-radius: 2px;
  &:active,
  &:focus,
  &:hover {
    border: 1px solid var(--main-semi-light-color) !important;
  }
  &::placeholder {
    color: var(--light-gray-button-back);
    font-size: 16px;
    line-height: 24px;
  }
}

.fieldContainer {
  position: relative;
}
