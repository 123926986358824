.column{
    width: max-content;
    max-width: 100%;
    margin-left: 15px;
    margin-right: 15px;
    &-span-1{
        width: 4.16%;
        max-width: 4.16%;
    }
    &-span-2{
        width: 8.3%;
        max-width: 8.3%;
    }
    &-span-3{
        width: 8.3%;
        max-width: 12.48%;
    }
    &-span-4{
        width: 16.64%;
        max-width: 16.64%;
    }
    &-span-5{
        width: 20.8%;
        max-width: 20.8%;
    }
    &-span-6{
        width: 24.96%;
        max-width: 24.96%;
    }
    &-span-7{
        width: 29.12%;
        max-width: 29.12%;
    }
    &-span-8{
        width: 33.28%;
        max-width: 33.28%;
    }
    &-span-9{
        width: 37.44%;
        max-width: 37.44%;
    }
    &-span-10{
        width: 41.6%;
        max-width: 41.6%;
    }
    &-span-11{
        width: 45.76%;
        max-width: 45.76%;
    }
    &-span-12{
        width: 49.92%;
        max-width: 49.92%;
    }
    &-span-13{
        width: 54.08%;
        max-width: 54.08%;
    }
    &-span-14{
        width: 58.24%;
        max-width: 58.24%;
    }
    &-span-15{
        width: 62.4%;
        max-width: 62.4%;
    }
    &-span-16{
        width: 69.56%;
        max-width: 66.56%;
    }
    &-span-17{
        width: 70.72%;
        max-width: 70.72%;
    }
    &-span-18{
        width: 74.88%;
        max-width: 74.88%;
    }
    &-span-19{
        width: 79.04%;
        max-width: 79.04%;
    }
    &-span-20{
        width: 83.2%;
        max-width: 83.2%;
    }
    &-span-21{
        width: 87.36%;
        max-width: 87.36%;
    }
    &-span-22{
        width: 91.52%;
        max-width: 91.52%;
    }
    &-span-23{
        width: 95.68%;
        max-width: 95.68%;
    }
    &-span-24{
        width: 100%;
        max-width: 100%;
    }
}