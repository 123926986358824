:root {
  
    --light-blue-color: #009fe3;
    --usual-button-color: #009fe3;
    --icon-button-back: #009fe3;
    --links-color: #009fe3;
    --medium-blue-color: #004a70;
    --usual-button-hover-color: #004a70;
    --main-elements-back: #004a70;
    --permanent-dark-blue: #004a70;
    --main-color: #333333;
    --not-acsent-color: #f5f5f5;
    --backlog: #ffffff;
    --bold-text: 700;
    --opacity-text: #cccccc;
    --gray-for-back: #d4d4d4;
    --gray-for-button: #d4d4d4;
    --gray-buttons-color: #ffffff;
    --gray-buttons-hover: #c9c9c9;
    --gray-buttons-active: #009fe3;
    --gary-buttons-decore-brightness: 100%;
    --error-color: #ff6e6f;
    --success-text: #009fe3;
    --shade: rgba(51, 51, 51, 0.15);
    --sigins-gray: #c9c9c9;
    --container-shadow: rgba(0, 0, 0, 0.25);
    --hover-color: #008fcc;
    --links-hover-color: #008fcc;
    --light-hover-cover: rgba(0, 143, 204, .1);
    --lg: 1400px;
    --pre-lg: 1200px;
    --middle: 992px;
    --light-hover-color: #E5F5FC;
    --ukhd-background-gray: rgba(51, 51, 51, .25);
    --background-dark-gray: rgba(0, 0, 0, .40);
    --windows-back-color: #005D8C;
    --gray-color: #CCCCCC;
    //storybook
    --base-button-background: transparent;
    --light-button-hover: #DCF4FF;
    --light-button-tap: #E7F8FF;
    --most-lite: #FFFFFF;
    --most-dark: #333333;
    --dark-button-color: #009FE3;
    --dark-button-hover: #00A4EA;
    --menu-hover-button: #00537E;
    --dark-button-tap: #0BB6FF;
    --gray-button-color: #CCCCCC;
    --light-gray-button-back: #ECECEC;
    --light-gray-color: #f5f5f5;
    --gray-signs-text: #B0B0B0;
    --color-theme-text-color: #004A70;
    --dark-black: #1D1D1D;
    --hover-theme-color: #005D8C;
    --shade-color: rgba(51, 51, 51, 0.3);
    --signs-gray: #7D7D7D;
    --comment-text-color: #00537E;
    --main-semi-light-color: #3CB5EA;
      
  .gray-theme {
    --medium-blue-color: #333333;
    --main-elements-back: rgb(166, 166, 166);
    --usual-button-hover-color: #333333;
    //storybook
    --menu-hover-button: #999999;


  }
  .green-theme{
    --medium-blue-color: #333333;
    --main-elements-back: #005437;
    --light-blue-color: #A8C300;
    --links-color: #333333;
    --links-hover-color: #A0BA00;
    --usual-button-color: #A8C300;
    --usual-button-hover-color: #A0BA00;
    --gray-for-button: #F5F5F5;
    --gray-buttons-color: #333333;
    --gray-buttons-decore-brightness: 0%;
    --gray-buttons-hover: #EFEFEF;
    --gray-buttons-active: #E7EFB6;
    --icon-button-back: #333333;
    --light-hover-cover: #E8E8E8;
    --success-text: #A8C300;
     //storybook
     --main-semi-light-color: #AFCA0B;
     --light-button-hover: #EFF4CE;
     --light-button-tap: #F3F7DB;
     --dark-button-color: #AFCA0B;
     --dark-button-hover: #C0DE0A;
     --dark-button-tap: #A8C300;
     --menu-hover-button: #19654B;
    // --comment-text-color: #19654B;
  }
}

