.questionCollapseContainer {
  border-bottom: 1px solid var(--opacity-text);
  color: var(--main-color);
  padding-left: 88px;
  overflow-y: hidden;
  padding-top: 24px;
  padding-bottom: 24px;
  cursor: pointer;
  [role='collapse-title'] {
    background-color: transparent;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-transform: none;
    color: var(--medium-blue-color);
    // padding: 32px 0px;
    span {
      position: relative;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 5px;
        left: -73px;
        width: 2px;
        height: 18px;
        background-color: var(--dark-black);
        transition: transform 0.3s;
      }
      &::after {
        transform: rotate(90deg);
      }
    }
  }
  .collapseText {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    // text-transform: none;
    color: var(--main-color);
    // p {
    //   // padding-bottom: 24px;
    // }
  }
  &[collapsed='true'] {
    [role='collapse-title'] {
      span {
        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(135deg);
        }
      }
    }
  }
}

.container {
  margin-top: 136px;
}
.sectionTitle{
  font-size: 24px;
  line-height: 32px;
}