@import '../../../../../../style/themes';

.hint {
  &Container {
    background-color: var(--not-acsent-color);
    padding: 24px;
    border-radius: 12px;
    &.error {
      background-color: transparent;
      border: 1px solid var(--error-color);
      color: var(--error-color);
      .hint {
        &Title {
          border-bottom-color: var(--error-color);
          color: var(--error-color);
        }
        &Text {
          color: var(--error-color);
        }
      }
    }
  }
  &Title {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: var(--gray-for-back);
    text-transform: uppercase;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--gray-for-back);
    margin-bottom: 19px;
    img {
      padding-right: 12px;
    }
  }
  &Text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-color);
  }
}
