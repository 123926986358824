.addCommentForm {
  margin-bottom: 60px;
  position: relative;
  .userAvatar {
    width: 100%;
    max-height: 100%;
    &Container {
      height: 50px;
      width: 50px;
      min-width: 50px;
      margin-right: 20px;
      border-radius: 50px;
      background-color: var(--opacity-text);
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
  }
  .fieldsColumn {
    width: 100%;
    textarea {
      width: calc(100% - 20px);
      margin-top: 0px;
      min-height: 112px;
      max-height: 112px;
      border-radius: 4px;
      padding: 10px;
      padding-top: 28px;
      &::placeholder {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
      }
    }
    .buttonRow {
      justify-content: flex-end;
    }

    .sendComment {
      background-color: var(--backlog);
      border: 1px solid var(--gray-button-color);
      color: var(--light-blue-color);
      padding-right: 30px;
      padding-left: 30px;
      width: max-content;
      &:hover {
        background-color: var(--light-blue-color);
        color: var(--backlog);
      }
      &.disable {
        background-color: var(--opacity-text);
        color: var(--backlog);
        border-color: var(--opacity-text);
        &:hover {
          background-color: var(--opacity-text);
          color: var(--backlog);
          border-color: var(--opacity-text);
        }
      }
    }
    .ButtonCol{
        display: flex;
        width: calc(100% - 33px);
        justify-content: flex-end;

    }
  }
}

.fieldError {
  color: var(--error-color);
  font-size: 16px;
  line-height: 24px;
}
.resultMessagePadding {
  position: absolute;
  top: 206px;
  left: 0px;
  max-width: calc(100% - 118px);

  padding-left: 71px;
}
