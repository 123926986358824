.panelConatiners {
  position: absolute;
  top: 22px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  min-width: 277px;
  padding-top: 18px;
  z-index: 1050;
  width: max-content;
  .panelArea {
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--most-lite);
    width: 100%;
    border-radius: 12px;
    height: auto;
    overflow: hidden;
    &:before {
      content: '';
      top: -50px;
      height: 20px;
      width: 20px;
    }
    .avatarContainer {
      position: absolute;
      height: 69px;
      width: 69px;
      overflow: hidden;
      top: 27.5px;
      left: 0;
      right: 0;
      justify-content: center;
      border-radius: 90px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      background-color: var(--most-lite);
      z-index: 1005;
      text-align: center;
      img,
      svg {
        &.avatarImg {
          min-height: 69px;
          min-width: 69px;
          max-width: 69px;
          max-height: 69px;
          border-radius: 69px;
          height: auto;
          width: auto;
          object-fit: cover;
        }
      }
    }
    .usertextInfo {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      padding-bottom: 24px;
      .userName {
        color: var(--main-color);
        padding-top: 13.5px;
      }
      .userEmail {
        color: var(--opacity-text);
        white-space: nowrap;
      }
    }
  }
}

.userButtons {
  display: flex;
  justify-content: space-between;
  .button {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
    a {
      color: var(--main-color);
    }
    &.blueButton {
      a {
        color: var(--light-blue-color);
      }
    }
  }
}
.transparentLevel {
  position: relative;
  padding: 24px;
  padding-top: 97.5px;
}
.avatarBack {
  background-color: var(--gray-button-color);
  position: absolute;
  height: 60px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
