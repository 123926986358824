.cornerPopup{
    right: 40px !important;
    bottom: 40px !important;
    margin: 0 !important;
    top: unset !important;
    left: unset !important;
    box-shadow: 0px 1px 2px 0px #0000004D;
    background-color: var(--windows-back-color) !important;
    color: var(--backlog) !important;
    a{
        color: var(--backlog) !important;
        font-weight: 500 !important;
    }
    .title{
        color: var(--backlog) !important;
    }
    .cross{
        &::before, &::after{
            background-color: var(--backlog) !important;
        }
        &:hover{
            background-color: transparent !important;
            transform: scale(1.2);
        }
    }

}

.container {
    padding-left: 376px;
    padding-right: 224.5px;
    flex-direction: column;
    padding-top: 48px;
    margin-top: 0;
  }

  @media (max-width: 1800px) {
    .container {
      padding-left: 376px;
      padding-right: 70px;
    }
  }
  
  @media (max-width: 1366px) {
    .container {
      padding-left: 376px;
      padding-right: 40px;
    }
  }
