.container{
    width: max-content;
    padding: 1px;
    font-size: 16px;
    font-weight: 400;
    color: --most-dark;
    cursor: pointer;
    &:hover{
        background-color: var(--light-button-hover);
    }
}
.userButtonConatiner{
    position: relative;
}