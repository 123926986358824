.commentPadding {
  padding-top: 80px;
  padding-bottom: 100px;
  padding-left: 15px;
  position: relative;
  .commentList {
    .commnetItem {
      margin-top: 48px;
      display: flex;
      justify-content: flex-start;
      .authorAvatar {
        width: 100%;
        height: auto;
        &Container {
          height: 50px;
          width: 50px;
          min-width: 50px;
          border-radius: 50px;
          background-color: var(--opacity-text);
          overflow: hidden;
          margin-right: 20px;
          background-repeat:CommentList no-repeat;
          background-size: cover;
          background-position: 50% 50%;
        }
      }
      .commentMetaInfo {
        display: flex;
        justify-content: start;
        color: var(--comment-text-color);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        .authorTitle {
          font-weight: 500;
          position: relative;
          padding-right: 24px;
        }
        &Part {
          width: max-content;
          font-weight: 300;
        }
        .functionalButtonsArea {
          display: flex;
          justify-content: flex-end;
          margin-top: -1.5px;
          cursor: pointer;
          opacity: 0;
          transition: opacity 0.3s;
          padding-left: 20px;
          .functinalButton {
            text-align: center;
            img {
              height: 13px;
              transform: scale(1);
              transition: transform 0.3s;
            }
            &.visible {
              opacity: 1;
            }
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
      .commnetText {
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 16px;
        font-weight: 300px;
        line-height: 24px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: keep-all;
        line-break: auto;
        hyphens: manual;
      }
      &:hover {
        .commentMetaInfo {
          .functionalButtonsArea {
            opacity: 1;
          }
        }
      }
      .replayBlock{
        padding-top: 18px;
        .commnetItem{
          padding-top: 28px;
          display: flex;
          flex-direction: flex-start;
        } 
      }
      .commnetItem{

      }
    }
  }
  .buttonRow {
    justify-content: flex-end;
  }
}
.commentError {
  padding-left: 8.3%;
  color: var(--error-color);
}
.emptyCommentList {
  padding-top: 60px;
  padding-left: 71px;
  font-style: italic;
  color: var(--opacity-text);
}

.commentData {
  width: calc(100% - 70px);
}
.commentFeaturesContainer {
  display: flex;
  justify-content: space-between;
  .textButtons{
    display: flex;
    justify-content: flex-start;
    flex-grow: 2;
  }
  .featureItem {
    font-size: 16px;
    line-height: 24px;
    color: var(--medium-blue-color);
    position: relative;
    padding-right: 27px;
    &:before {
      content: '';
      position: absolute;
      right: 10px;
      height: 3px;
      width: 3px;
      background-color: var(--opacity-text);
      border-radius: 3px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
    &:last-child {
      &:before {
        content: none;
      }
    }
    &:hover {
      color: var(--usual-button-color);
      cursor: pointer;
    }
  }
}
.loadingCommentList {
  padding-top: 70px;
  padding-left: 71px;
  color: var(--success-text);
}

.likeButton {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  img {
    height: 24px;
    padding-top: 0px;
    padding-bottom: 3px;
    vertical-align: middle;
    display: none;
    &.visible{
      display: block;
    }
  }
  span{
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    margin-left: 12px;
    width: 12px;
    color: var(--dark-black);
    vertical-align: middle;
    display: block;
    &.active{
      color: var();
    }
  }
  &:hover{
    cursor: pointer;
  }
}

.loadingText{
  color: var(--light-blue-color);
  font-size: 16px;
  padding-top: 28px;
}

