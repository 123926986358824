.imageContainer {
  width: 100%;
  padding-top: 72px;
  cursor: pointer;
  .imageOuter {
    transition: filter 0.3s;
    width: 100%;
    position: relative;
    .errorLoading{
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      top:0;
      left: 0;
      .sign{
        position: absolute;
        width: 100%;
        height: max-content;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        text-align: center;
        span{
          color: var(--error-color);
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
    img {
      width: auto;
      height: auto;
    }
    &:hover {
      filter: brightness(0.8);
    }
  }
}
.imageSign {
  font-size: 16px;
  font-weight: 300;
  padding-bottom: 30px;
  padding-top: 48px;
  .boldText {
    font-weight: 400;
    padding: 12px 0;
    margin-top: 5px;
    margin-left: 2px;
    font-weight: 500;
  }
}
.invisible {
  display: none;
}
.inputsSigns {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--opacity-text);
  text-align: left;
}

.loadingImage{
  filter: blur(4px);
}