.verticalMenu {
  position: fixed !important;
  width: 338px !important;
  max-width: 338px !important;
  height: 100vh;
  flex: 0 0 338px !important;
  left: 0px;
  top: 0px;
  padding: 48px 0px;
  color: var(--backlog);
  z-index: 100000;
  background-color: var(--main-elements-back) !important;

  .subtitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
    padding-top: 4px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .logo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 30px;
    gap: 10px;

    &Link {
      font-weight: 300;
      font-size: 32px;
      line-height: 32px;
      color: var(--most-lite) !important;
      transition: color 0.3s;
      font-weight: 500;
      text-decoration: none;
      span {
        transition: color 0s;
        opacity: 0.7;
      }
      &:hover {
        color: var(--light-blue-color) !important;
      }
    }
  }
  .search {
    &Area {
      padding-top: 60px;
      margin-top: 0px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

.menuContainer {
  margin-top: 40px;
  max-height: calc(100vh - 306px);
  overflow-y: auto;
}

.categoryTitle {
  padding: 8px 30px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}

.loagingsignContainer {
  text-align: center;
  padding-top: 20px;
}

.errorContainer {
  padding: 60px 30px;
  span {
    color: var(--most-lite) !important;
  }
}
