
footer{
    background-color: transparent !important;
    padding-inline: 0 !important;
    display: flex;
    justify-content: space-between;
    margin-top: 276px;
    margin-bottom: 50px;
    // padding-bottom: 70px;
    // padding-top: 90px;

    .logoContainer{
        margin-top: 80px;
        .bottomLogo{
            padding-bottom: 17px;
        }
    }
}

.menu{
    margin-top: 232px;
}
.smallMenu{
    margin-top: 80px;
}