.container{
    background-color: var(--light-gray-color);
    padding: 24px;
    border-radius: 12px;
    font-size: 14px;
    line-height: 20px;
    margin-top: 62px;
    margin-bottom: 41px;
}

.containerStyle{
    margin-top: 0px;
}

.descriptorContainer {
    font-size: 16px;
    line-height: 30px;
    text-transform: uppercase;
    color: var(--gray-button-color);
    padding-bottom: 16px;
    border-bottom: 1px solid var(--gray-button-color);
    margin-bottom: 24px;

    img{
        height: 40px;
        margin-right: 12px;
    }
}