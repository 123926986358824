.container {
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  cursor: pointer;
  width: max-content;
  vertical-align: middle;
  height: max-content;
  transition: background-color 0.3s, transform 0.3s;
  &:active {
    transform: scale(0.9);
  }
  &__normal {
    background-color: var(--base-button-background);
    color: var(--most-dark);
    &:hover {
      background-color: var(--light-button-hover);
    }
    &:active,
    &:focus {
      background-color: var(--light-button-tap);
    }
    &.rounded {
      background-color: var(--light-gray-button-back);
      &:hover {
        background-color: var(--light-button-hover);
      }
      &:active,
      &:focus {
        background-color: var(--light-button-tap);
      }
    }
  }

  &__filled {
    background-color: var(--dark-button-color);
    color: var(--most-lite);
    &:hover {
      background-color: var(--dark-button-hover);
    }
    &:active,
    &:focus {
      background-color: var(--dark-button-tap);
    }
  }
  &__outlined {
    background-color: var(--base-button-background);
    color: var(--dark-button-color);
    border: 1px solid var(--dark-button-color);
    &:hover {
      background-color: var(--light-button-hover);
    }
    &:active,
    &:focus {
      background-color: var(--light-button-tap);
    }
  }
  &__grayOutlined {
    background-color: var(--base-button-background);
    border: 1px solid var(--gray-button-color);
    color: var(--gray-button-color);
    filter: grayscale(1);
    &:hover {
      filter: grayscale(0);
    }
    &:focus {
      background-color: var(--dark-button-color);
      filter: grayscale(0);
    }
    &:active {
      background-color: var(--dark-button-color);
      filter: grayscale(0);
    }
  }

  .contentLine {
    display: inline-block;
    vertical-align: middle;
    .image {
      text-align: center;
    }
  }
  .image {
    vertical-align: middle;
    padding: 3.78px;
    padding-right: 0;
    &__before {
      margin-right: 10px;
    }
    &__after {
      margin-left: 10px;
    }
  }
  &.rounded {
    border-radius: 40px;
  }
}
