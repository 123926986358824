.form {
    background-color: var(--most-lite);
    padding: 8px 8px 8px 16px;
    display: flex;
    justify-content: flex-start;
    border-radius: 4px;
    border: 1.5px solid var(--most-lite);
  .field {
    font-size: 14px;
    line-height: 20px;
    border: none;
    outline: none;
    padding: 0 8px;
    margin:0;
    color: var(--dark-black);
    font-weight: 300;
    &:hover, &:focus{
      border: none;;
    }
  }
  .button{
    height: 24px;
    width: 24px;
    background-color: transparent;
    padding: 0;
    &:hover, &:focus, &:active{
        transform: scale(1.1);
    }
  }
  &:hover, &:focus{
    border: 1.5px solid var(--main-semi-light-color);
  }
}

