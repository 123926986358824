.dataListContainer{
  height: max-content;
  position: absolute;
  .disabler{
    position: absolute;
    z-index: 100;
    top:0px;
    left: 0px;
    height: 0;
    width: 100%;
    background-color: rgba(255,255,255, 0.7);
    transition: height 0.3s;
    &.activated{
      height: 100%;
    }
  }
}
.selector {
  cursor: pointer;
  font-weight: 300;
  font-size: 14x;
  line-height: 20px;
  // text-transform: uppercase;
  color: var(--dark-black);
  &Container {
    // padding-bottom: 24px;
  }
  &Value {
    border: 1px solid var(--gray-button-color);
    border-radius: 4px;
    padding: 8px 8px 8px 16px;
    position: relative;
    overflow-wrap: anywhere;
    &::before, &::after {
      content: '';
      position: absolute;
      right: 10px;
      top: 19px;
      border: 6px solid transparent;
      border-top: 6px solid var(--main-color);
      transition: transform 0.3s, top 0.3s;
    }
    &::after{
        right: 12px;
        border: 4px solid transparent;
        border-top: 4px solid var(--backlog);

    }
    &.opened{
        &::after, &::before{
            transform: rotate(180deg);
            top: 13px;
        }
        &::after{
            top: 17px;
        }
    }
    &List {
      // border: 1px solid var(--gray-for-back);
      background-color: var(--most-lite);
      margin-top: 4px;
      padding: 8px 0;
      border-radius: 4px;
      box-shadow: 0px 2px 6px 2px #00000026;
      box-shadow: 0px 1px 2px 0px #0000004D;
      overflow: hidden;
    }
  }
  &Item {
    padding: 8px 16px;
    line-height: 21px;
    overflow-wrap: anywhere;
    &:hover{
      background-color: var(--light-gray-button-back);
    }
  }
}

.datList{
  font-size: 15px;
}