.darkMode {
  background-color: var(--not-acsent-color);
  border-color: var(--not-acsent-color) !important;
  height: 100%;
  overflow: hidden;
}
.bigMargin {
  margin-bottom: 30px;
}
.canHover {
  &:hover {
    background-color: var(--not-acsent-color) !important;
    border-color: var(--not-acsent-color) !important;
    cursor: pointer;
    [role="title"] {
      color: var(--light-blue-color) !important;
    }
  }
}

.fullFillMode {
  height: 100%;
}

.withMargin {
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 16px);
    margin-top: -20px;
  }
  
  .articleTeg {
    position: relative;
  }

.noBorderCards {
  border: none;
  min-height: 300px;
}

.paddingTop{
  height: 32px;
}

@media(max-width: 1530px){
  .noBorderCards{
    min-height: 330px;
  }
}

@media(max-width: 1430px){
  .noBorderCards{
    min-height: 360px;
  }
}


  @media (max-width: 1366px) {
    .withMargin{
      margin-top:20px;
    }
  }

  @media(max-width: 1290px){
    .noBorderCards{
      min-height: 415px;
    }
  }