
.contentBlock {
  max-width: 100%;
  overflow: hidden;
  overflow-wrap: break-word;
  color: var(--main-color);
  h1{
    font-size: 48px;
    line-height: 64px;
    font-weight: 500;
  }
  ul {
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ol {
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  a {
    text-decoration: underline;
    color: var(--main-color);
    &:hover {
      color: var(--light-blue-color);
    }
  }
  img{
    max-width: 100%;
  }

  strong,
  u,
  s,
  blockquote {
    font-style: inherit;
  }
  blockquote {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    padding-left: 16px !important;
  }

  h2 {
    &.subTitle {
      color: var(--medium-blue-color);
      // text-transform: uppercase;
      font-size: 24px;
      font-weight: 600;
      padding-top: 48px;
      line-height: 36px;
      margin-bottom: 48px;
      font-weight: 500;
      padding-bottom: 24px;
      border-bottom: 1px solid var(--opacity-text);
    }
  }
  .content {
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    line-height: 30px;
    // min-height: 335px;

    figure {
      margin: 0px;
      padding-top: 24px;
      padding-bottom: 24px;
      figcaption {
        line-height: 30px;
        font-size: 16px;
        font-weight: 400;
      }
    }
    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 10px;
    }
    p {
      margin-bottom: 1em;
    }
  }

  .paragraphContainer {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    padding: 0 !important;
    line-height: 30px;
  }
}
.titleBlock {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--opacity-text);
  padding-left: 0;
  transition: opacity 0.3s;
  padding-bottom: 19px;
  margin-bottom: 48px;

  .fuctionalButton {
    padding: 4px;
    cursor: pointer;
    height: 50px;
    white-space: nowrap;
    margin-left: 30px;
    svg,
    img {
      height: 24px;
      width: 24px;
    }
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 50px;
      color: var(--main-color);
    }
    &.edit {
      opacity: 0.2;
    }
    &.savePdf {
      height: 24px;
      margin-top: 12px;
      margin-bottom: 12px;
      margin-left: 30px;
      opacity: 0.3;

    }
    &:hover {
      opacity: 1;
    }
  }
  h1 {
    font-weight: 600;
    font-size: 32px;

    color: var(--medium-blue-color);
    margin: 0px;
    line-height: 56px;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    flex-grow: 6;
  }
}

.darkMode {
  padding: 24px;
  display: flex;
  transition: height 0.2s;
  justify-content: space-between;
  flex-direction: column;
  background: var(--light-gray-color);
  border-radius: 12px;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: var(--main-color);
  border-color: transparent !important;
  margin-left: 0px;
  width: calc(100% - 429px);
  min-height: 80px !important;
  height: 100%;
  // margin-bottom: 50px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  p {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
  }

  &.fullSize {
    width: 100%;
    margin-bottom: 0px;
  }
}

.PreviewBlock {
  display: flex;
  justify-content: space-between;
  // height: max-content;
  .mainImage {
    float: right;
    width: 412px;
    min-width: 412px;
    // height: 297px;
    margin-left: 32px;
    overflow: hidden;
    padding-left: 32px;
    background: var(--backlog);
    margin-left: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    text-align: center;
    vertical-align: middle;
    img {
      width: 100%;
      height: auto;
    }
  }
  .previewText {
    width: 100%;
    min-height: max-content !important;
    &Container {
      width: 100%;
      height: 100%;
      // &.withMargin {
      //   margin-right: 32px;
      // }
    }
    &.withMinHeight {
      // min-height: 297px !important;
    }
  }
}

.noMargin {
  margin-left: 0px;
}
.linkList {
  a {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    color: var(--light-blue-color);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: var(--light-blue-color);
    }
  }
}


#pdf-generator {
  padding-left: 30px;
  padding-right: 30px;
  max-width: 590px;
}

.sheareLinkConatiner {
  padding-left: 15px;
  width: max-content;
  margin-top: 72px;
  .sheareLinkItem {
    filter: grayscale(1);
    text-decoration: none;
    opacity: 0.5;
    font-size: 16px;
    line-height: 24px;
    font-size: 300;
    transition: filter 0.2s, opacity 0.2s;
    span {
      font-size: 16px;
      line-height: 24px;
      font-style: normal;
      font-weight: 300;
      color: var(--dark-button-color);
    }
    img,
    svg {
      margin-left: 12px;
    }
    &:hover {
      filter: grayscale(0);
      opacity: 1;
    }
  }
}

.articleHeaderButton{
  background-color: transparent;
  border: 1px solid var(--gray-color);
  color: var(--gray-color) !important;
  font-weight: 400;
  transition: border 0.1s, color 0.1s;
  padding: 8px 32px;
  margin: 3px;
  margin-bottom: 8px;
  cursor: pointer;
  height: 50px;
  width: max-content;
  min-width: 160px;
  img{
    filter: brightness(0);
    opacity: 0.15;
    transition: filter 0.3s, opacity 0.3s;
  }
  &:hover, &:focus{
    background-color: transparent;
    border: 1px solid var(--usual-button-hover-color);
    color: var(--usual-button-hover-color) !important;
    img{
      filter: brightness(1);
      opacity: 1;
    }
  }
}

.invisible{
  display: none;
}

.buttonTools{
  margin-left: 24px;
}