.carousel {
  overflow: hidden;
  &Inner {
    display: flex;
    height: max-content;
    .item {
      min-width: 100%;
      max-width: 100%;
      max-height: 0px;
      opacity: 0;
      transition: max-height 0.4s, opacity 0.4s;
      margin-bottom: 38px;
      &.active{
        max-height: 100000000px;
        opacity: 1;
      }
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  .gray{
    background-color: var(--gray-for-button);
    color: var(--gray-buttons-color);
    img{
      filter: brightness(var(--gray-buttons-decore-brightness));
    }
    &:hover{
      background-color: var(--gray-buttons-hover);
    }
    &:focus, &:active{
      background-color: var(--gray-buttons-active);
    }
  }
  img {
    vertical-align: middle;
    &.marginLeft {
      margin-left: 10px;
    }
    &.marginRright{
        margin-right: 10px;
    }
  }
  .button{
    margin-left: 24px
  }
}

.isPaused{
  opacity: 0.3;
  background-color: var(--usual-button-color) !important;
  &.gray{
      background-color: var(--gray-for-button) !important;
  }

}