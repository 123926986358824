.emptyContent {
  &Container {
    min-height: 392px;
    border-radius: 12px;
    background-color: var(--not-acsent-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.functionsBlock {
  height: max-content;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}
.startTitle {
  font-size: 16px;
  line-height: 18px;
  color: var(--opacity-text);
  padding-bottom: 10px;
  width: max-content;
  cursor: default;
}
.button {
  &Container {
    height: 32px;
    width: 32px;
    border-radius: 2px;
    background-color: var(--opacity-text);
    padding: 7px;
    margin: 7px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    &:hover {
      background-color: var(--icon-button-back);
    }
    img {
      max-height: 28px;
    }
    img {
      &.startButton {
        filter: invert(1) brightness(100);
        height: 13px;
        border-radius: 0px;
      }
    }
    &.usualModeButton {
      background-color: var(--backlog);
      text-align: center;
      width: max-content;
      padding: 20px;
      padding-right: 24px;
      padding-left: 24px;
      padding-bottom: 10px;
      height: auto;
      border-radius: 4px;
      span {
        color: var(--main-color);
        font-size: 14px;
        line-height: 36px;
      }
      &:hover {
        background-color: var(--not-acsent-color);
      }
      &:active {
        background-color: var(--main-elements-back);
        transform: scale(0.6);
      }
    }
  }
  &List {
    display: flex;
    justify-content: center;
  }
}
.newBlockButtonsArea {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: -105px;
  z-index: 1001;
}

.addBlockButton {
  position: absolute;
  height: 24px;
  width: 24px;
  background-color: var(--main-color);
  border-radius: 24px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  bottom: -12px;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  &Container {
    position: relative;
    height: 40px;
    opacity: 0;
    transition: opacity 0.3s;
    padding-bottom: 16px;
    z-index: 1000;
    &.blocked{
      opacity: 0.4;
      cursor: auto;
    }
    &.visible {
      opacity: 1;
      &.blocked{
        cursor: auto;
        opacity: 0.4;
      }
    }
    .liner {
      border-bottom: 1px dashed var(--opacity-text);
      height: 100%;
      position: relative;
    }
    &:hover {
      cursor: pointer;
      // .addBlockButton {
      //   background-color: var(--light-blue-color);
      // }
      &.blocked{
        cursor: auto;
        opacity: 0.4;
      }
    }
  }
  &:before,
  &:after {
    content: '';
    background-color: var(--backlog);
    height: 11.5px;
    width: 1px;
    position: absolute;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    top: 6.5px;
  }
  &:after {
    transform: rotate(90deg);
  }
  &:active {
    transform: scale(0.6);
  }
}
.blockContainer {
  position: relative;
  padding-bottom: 0px;
  // z-index: 1001;
  .functionalButtonsContainer {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    transition: opacity 0.3s;
    .functionalButtons {
      height: 32px;
      width: 32px;
      border: 1px solid var(--opacity-text);
      border-right: none;
      text-align: center;
      vertical-align: middle;
      position: relative;
      img {
        filter: invert(0);
        height: 14px;
        width: auto;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: filter 0.3s;
      }
      transition: background-color 0.3s;
      &:hover {
        cursor: pointer;
        background-color: var(--not-acsent-color);
      }
      &:last-child {
        border-right: 1px solid var(--opacity-text);
      }
      &.blocked {
        background-color: var(--not-acsent-color);
        img {
          filter: invert(1);
        }
      }
      &:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
      &:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }
  &:hover {
    .functionalButtonsContainer {
      opacity: 1;
      
    }
  }
}

.notEmptyContentContainer {
  padding-bottom: 80px;
}

.fileSectionContainer {
}
.buttonListContainer {
  .fileButtonItem {
    cursor: pointer;
    img {
      padding: 9px;
      padding-right: 0px;
      height: 30px;
    }
  }
}
.invisbleFilesAddForm {
  display: none;
}
.fileItemButtonsContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 14px;
  left: 0px;
  .fileButtonItem {
    text-align: center;
    background-color: var(--light-hover-color);
    border-radius: 2px;
    margin-right: 8px;
    a,
    .imageContainer {
      display: block;
      padding: 6px 13px;
      height: 100%;
      width: 100%;
    }
    img {
      height: 13px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.fileLoading {
  filter: blur(4px);
  .errorLoading {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    .sign {
      position: absolute;
      width: 100%;
      height: max-content;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      text-align: center;
      span {
        color: var(--error-color);
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}
