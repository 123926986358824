@import "../../../../../../style/themes";

.linkInfoContainer{
    background-color: var(--not-acsent-color);
    width: max-content;
    border-radius:6px;
    padding: 12px;
    padding-right: 54px;
    position: relative;
}

.linkSection{
    padding-bottom: 48px;
}