.container {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 1366px) {
    margin-top: 35px;
  }

  @media print {    
    padding-left: 25px;
  }
}
