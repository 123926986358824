@import 'normalize.css/normalize';
@import './base.scss';
@import './themes';
@import './fonts/fonts';

* {
  font-family: 'Poppins';
  font-style: normal;
  transition: color 0.3s, background-color 0.3s, font-weight 0.3s,
    transform 0.3s, border-color 0.3s;
}
body {
  background-position: 100% 0;
  background-repeat: no-repeat;
  font-size: 16px;
  font-weight: 300;
}
em {
  font-style: italic;
}
strong {
  font-weight: 600;
}
blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}
section {
  background-color: transparent;
  background: transparent;
  &.css-dev-only-do-not-override-10ed4xt,
  &.css-10ed4xt {
    background-color: transparent;
    background: transparent;
    &.ant-layout {
      background-color: transparent;
      background: transparent;
    }
  }
}

input,
textarea {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid var(--gray-for-back);
  padding-bottom: 12px;
  padding-top: 12px;
  margin-top: 9px;
  &::placeholder {
    color: var(--gray-for-back);
    font-weight: 300;
  }
  // &:not([type='submit']) {
  //   &:focus,
  //   &:hover {
  //     border-bottom: 1.5px solid var(--light-blue-color);
  //   }
  // }
  // &[type='submit'] {
  //   // border: 1px solid transparent;
  //   &:focus,
  //   &:hover {
  //     border: 1.5px solid transparent;
  //   }
  // }
}
textarea {
  min-height: 312px;
  padding: 24px;
  border: 1px solid var(--gray-for-back);
  border-radius: 12px;
  // &:focus,
  // &:hover {
  //   border: 1px solid var(--light-blue-color);
  // }
}
