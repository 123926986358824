@import '../../../../style/themes';

.PopupHint {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 4px 8px;
  border-radius: 2px;
  background-color: var(--main-color);
  color: var(--backlog);
  text-align: center;
  white-space: nowrap;
  width: max-content;

  &Container {
    position: absolute;
    z-index: 999900000;
  }
  &::before {
    content: '';
    height: 10px;
    width: 10px;
    border: 10px solid transparent;
    border-bottom: 10px solid var(--main-color);
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.hidden {
  opacity: 0;
}
