.container {
  margin-left: -15px;
  margin-right: -15px;
}

.cardView {
  margin-bottom: 30px;
}
.postCard {
  margin-left: 0px;
 
}
.bigPostCard{
  width: calc(100% + 444px);
  margin-left: -444px;
}

.cardListcontainer {
  margin-bottom: 32px;
}