.userFormContainer {
  border: 1px solid var(--gray-for-back);
  border-radius: 12px;
  padding: 56px 55px;
  position: absolute;
  margin-top: 16px;
  z-index: 100;
  background-color: var(--backlog);
}

.avatraConatiner {
  padding-left: 0px 13px;
  padding-bottom: 20px;
  position: relative;
  width: max-content;
  .avatarImageContainer {
    height: 130px;
    width: 130px;
    text-align: center;
    border-radius: 140px;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  &:hover, &:focus{
    .changeAvatarBtn{
      display: block;
    }
  }
  .changeAvatarBtn {
    display: none;
    position: absolute;
    height: 25px;
    height: 25px;
    transition: transform 0.2s, filter 0.2s;
    left: 105px;
    top: 105px;
    cursor: pointer;

    img {
      height: 100%;
      width: auto;
      filter: brightness(0.1);
      transition: filter 0.2s;
      &:hover,
      &:focus {
        filter: brightness(1);
      }
    }
    &:hover {
      // transform: scale(1.1);
    }
  }
}

.invisible {
  display: none;
}

.inputsSigns {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: var(--opacity-text);
}

.userFromsInputs {
  margin-top: 28px;
}

.logoutFromAllDevisesBtn {
  margin-top: 28px;
}

.buttons {
  border: 1px solid var(--light-blue-color);
  border-radius: 50px;
  background-color: transparent !important;
  color: var(--light-blue-color) !important;
  padding: 8px 20px;
  width: max-content;
  min-width: 40%;
  display: block;
  margin-top: 28px;
  background: transparent !important;
  &:hover {
    background-color: var(--light-blue-color) !important;
    color: var(--backlog) !important;
    border: 1px solid var(--light-blue-color);
  }
}

.submitButton {
  text-align: center;
  text-transform: none;
  margin-top: 32px;
  width: 100%;
  &.bottomForm {
    margin-top: 60px;
  }
}

.topFild {
  margin-top: 40px;
}

.bottomButtonArea{
  margin-top: 60px
}

.formTitle {
  font-size: 20px;
  line-height: 28px;
  &Container {
    margin-top: 112px;
    margin-bottom: 8px;
  }
}

.passwordFiels {
  &Icon {
    position: absolute;
    right: 0;
    bottom: 4px;
    .eyeOpened {
      display: block;
    }
    .eyeClosed {
      display: none;
    }
    &.closed {
      .eyeOpened {
        display: none;
      }
      .eyeClosed {
        display: block;
      }
    }
    &:hover{
      cursor: pointer;
    }
  }
}
