.tabsContainer {
  position: relative;
  max-width: calc(100% - 50px);
  .tabLinks {
    position: relative;
    display: flex;
    overflow: scroll;
    margin-bottom: 48px;
    // margin-right: 50px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .ItemName {
      // margin: 0 0 0 32px;
      // text-transform: uppercase;
      font-size: 14px;
      padding: 8px 16px 8px 16px;
      margin-bottom: 4px;
      line-height: 20px;
      white-space: nowrap;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
      &.active {
        color: var(--light-blue-color);
      }
    }
    &:before {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      border-bottom: 1px solid rgba(5, 5, 5, 0.06);
      bottom: 0;
    }
    .underline {
      position: absolute;
      bottom: 0;
      background-color: var(--light-blue-color);
      height: 2px;
      left: 0;
      transition: left 0.3s, width 0.3s;
    }
    &::-webkit-scrollbar, &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track, &::-webkit-scrollbar-butto {
      width: 0;
      height: 0;
      display: none;
      background: transparent;
    }

  }
  .arrow {
    position: absolute;
    right: -45px;
    height: 27px;
    width: 8px;
    top: 8px;
    right: 5px; 
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      position: absolute;
      border: 6px solid transparent;
      height: 10px;
      width: 10px;
      top: 2px;
      left: 2px;
      border-left: 6px solid var(--light-blue-color);
      transform: border-left-color 0.3s, border-right-color 0.3s;
    }
    &:after {
      left: 0;
      border-left: 6px solid var(--backlog);
    }
    &:hover{
        &:before{
            border-left-color: var(--permanent-dark-blue);
        }
    }
    &.arrowStart{
        z-index: 99;
        left: 0px;
        background-color: var(--backlog);
      
        &:before,
        &:after {
            left: 2px;
            border: 6px solid transparent;
            border-right: 6px solid var(--light-blue-color);
        }
        &:after {
            left: 4px;
            border-right: 6px solid var(--backlog);
        }
        &:hover{
            &::before{
                border-left-color: var(--backlog);
                border-right-color: var(--permanent-dark-blue);
            }
        }   
    }
  }
}
