.Preloader {
  position: fixed;
  flex-direction: column;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100001;
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  background: var(--main-elements-back);
  color: var(--backlog);
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 32px;
  h1 {
    font-size: 20px;
  }
  &.litenTheme {
    background: rgba(255, 255, 255, 0.5);
    color: var(--main-elements-back);
    .Loader {
      .LoaderOutter {
        border: 4px solid var(--main-elements-back);
        border-left-color: transparent;
        border-bottom: 0;
      }
      .LoaderInner {
        border: 4px solid var(--main-elements-back);
        border-left-color: transparent;
        border-bottom: 0;
        border-right: 0;
      }
    }
  }
}
.Loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 35px;
  display: inline-block;
  vertical-align: middle;
  .LoaderOutter {
    position: absolute;
    border: 4px solid var(--backlog);
    border-left-color: transparent;
    border-bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41)
      infinite;
    animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  }
  .LoaderInner {
    position: absolute;
    border: 4px solid var(--backlog);
    border-left-color: transparent;
    border-bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41)
      infinite;
    animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    height: 40px;
    width: 40px;
    border-right: 0;
    -webkit-animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41)
      infinite;
    animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  }
}

@-webkit-keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
