html {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0px;
}
a {
  color: inherit;
  &:hover {
    color: inherit;
  }
}

input,
button,
textarea {
  outline: none;
  border: none;
  &:hover {
    outline: none;
    // border: none;
  }
}

button,
input[type='submit'] {
  cursor: pointer;
}
ul,
ol,
p {
  padding: 0;
  margin: 0;
  margin-bottom: 0;
}
p {
  min-height: 16px;
}

ul,
ol {
  list-style-type: none;
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track,
::-webkit-scrollbar-butto {
  width: 0;
  height: 0;
  display: none;
  background: transparent;
}
