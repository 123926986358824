.container {
  padding: 0 50px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  .image {
    width: 40%;
  }
  .textContainer {
    text-align: center;
    &.department {
      color: #a6a6a6;
    }
    &.egk {
      color: #005437;
    }
    &.ukhd {
      color: #004a70;
    }
    h2 {
      font-size: 94px;
      font-weight: 500;
    }
    h3 {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
  }
}
