
   @font-face {
    font-family: "Poppins";
      src: url("../fonts/Poppins/Poppins-Regular.ttf");
      font-weight: normal;
      font-style: normal;
    }
    
    @font-face {
      font-family: "Poppins";
      src: url("../fonts/Poppins/Poppins-Bold.ttf");
      font-weight: bold;
      font-style: normal;
    }
    
    @font-face {
      font-family: "Poppins";
      src: url("../fonts/Poppins/Poppins-Light.ttf");
      font-weight: 300;
      font-style: normal;
    }
    

    @font-face {
      font-family: "Poppins";
      src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
      font-weight: 600;
      font-style: normal;
    }

    @font-face {
      font-family: "Poppins";
      src: url("../fonts/Poppins/Poppins-Medium.ttf");
      font-weight: 500;
      font-style: normal;
    }

  
  //  @font-face {
  //   font-family: "Commissioner";
  //     src: url("../fonts/Commissioner/Commissioner-Regular.ttf");
  //     font-weight: normal;
  //     font-style: normal;
  //   }
    
  //   @font-face {
  //     font-family: "Commissioner";
  //     src: url("../fonts/Commissioner/Commissioner-Bold.ttf");
  //     font-weight: bold;
  //     font-style: normal;
  //   }
    
  //   @font-face {
  //     font-family: "Commissioner";
  //     src: url("../fonts/Commissioner/Commissioner-Light.ttf");
  //     font-weight: 300;
  //     font-style: normal;
  //   }
    

  //   @font-face {
  //     font-family: "Poppins";
  //     src: url("../fonts/Commissioner/Commissioner-SemiBold.ttf");
  //     font-weight: 600;
  //     font-style: normal;
  //   }

  //   @font-face {
  //     font-family: "Poppins";
  //     src: url("../fonts/Commissioner/Commissioner-Medium.ttf");
  //     font-weight: 500;
  //     font-style: normal;
  //   }