.homepageBack {
  background-repeat: no-repeat !important;
  background-position: 100% 0 !important;
  // background-size: contain;
}
.container {
  padding-left: 376px;
  padding-right: 224.5px;
  flex-direction: column;
  padding-top: 48px;
  margin-top: 0;
}

.faqQuestionsContainer {
  margin-top: 0;
}

.faqQuestionTitle{
  margin-top: 136px;
  padding-bottom: 30px;
}

@media (max-width: 1800px) {
  .container {
    padding-left: 376px;
    padding-right: 70px;
  }
}

@media (max-width: 1366px) {
  .container {
    padding-left: 376px;
    padding-right: 40px;
  }
}