.mainBunnerContainer{
 padding: 48px 24px;
 border: 1px solid var(--gray-button-color);
 border-radius: 12px;
}
.logoBunerContainer {
  height: 68px;
  margin-bottom: 40px;
  img{
    min-height: 100%;
  }
}
.description {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--color-theme-text-color);    
}


