
.UserEditForm {
  max-width: 412px;
  width: 412px;
}

.container {
  padding-left: 376px;
  padding-right: 224.5px;
  flex-direction: column;
  padding-top: 48px;
  margin-top: 0;
}

.userInterfaceTabs {
  margin-top: 15px;
  min-height: 900px !important;
  div[role='tablist'] {
    margin-left: 450px !important;
    div[role='tab'] {
      text-transform: uppercase;
      color: var(--main-color) !important;
      &[aria-selected='true'] {
        color: var(--light-blue-color) !important;
      }
    }
  }
}

@media (max-width: 1800px) {
  .container {
    padding-left: 376px;
    padding-right: 70px;
  }
}

@media (max-width: 1366px) {
  .UserEditForm {
    max-width: 404px;
    width: 404px;
  }
  .userInterfaceTabs {
    div[role='tablist'] {
      margin-left: 450px !important;
    }
  }
  container {
    padding-left: 376px;
    padding-right: 40px;
  }
}

@media (max-width: 1290px) {
  .UserEditForm {
    max-width: 350px;
    width: 350px;
  }
  .userInterfaceTabs {
    div[role='tablist'] {
      margin-left: 400px !important;
    }
  }
}

