.faqPageConatiner{
    min-height: "100vh";
}

.sectionTitle{
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}

.faqQuestionsContainer {
  margin-top: 0;
}

.faqQuestionTitle{
  margin-top: 136px;
  padding-bottom: 30px;
}

.container {
    padding-left: 376px;
    padding-right: 224.5px;
    flex-direction: column;
    padding-top: 48px;
    margin-top: 0;
  }

  @media (max-width: 1800px) {
    .container {
      padding-left: 376px;
      padding-right: 70px;
    }
  }
  
  @media (max-width: 1366px) {
    .container {
      padding-left: 376px;
      padding-right: 40px;
    }
  }