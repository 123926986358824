.container {
  padding: 0px;
  position: relative;
  min-height: 100vh !important;
  height: max-content;
  margin: 0px;
  &.darkBlue {
    background-color: var(--medium-blue-color);
  }
  display: flex;
  justify-content: center;
}

.formContainer {
  margin: auto;
  height: max-content;
  width: max-content;
  max-width: 541px;
  form {
    input,
    label {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      &[type='checkbox'] {
        width: auto;
        margin-left: 3.5px;
        margin-right: 11.5px;
        margin-top: 7.5px;
        margin-bottom: 7.5px;
      }
    }
    label{
      line-height: 32px;
      font-size: 16px;
    }
  }
}
.error {
  margin-top: 12px;
  color: var(--error-color);
  margin-bottom: 12px;
  &.success {
    color: var(--success-text);
  }
}

.mainTitle {
  font-size: 24px;
  color: var(--backlog);
  line-height: 32px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
  span {
    font-weight: 700;
  }
}
.formTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--color-theme-text-color);
  text-align: center;
}
.subTitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.fieldsArea {
  background-color: var(--most-lite);
  width: 512px; 
  padding: 50px 56px;
}
.linksArea {
  padding: 16px 0px 0 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-button-color);
  text-align: center;
  a {
    color: var(--dark-button-color) !important;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: var(--dark-button-hover) !important;
    }
  }
}
.label{
  font-size: 16px;
}
.inputContainer{
  margin-top: 44px;
  &:first-child{
    margin-top: 32px;
  }
  &.checkboxChild{
    margin-top: 34px;
  }
}
.checkboxSquare{
  display: inline;
}
.error{
  font-size: 14px;
  margin: 0;
  margin-top: 10px;
}

.formButton{
  width: 100%;
  text-align: center;
  margin-top: 32px;
}

.passwordFiels {
  &Icon {
    position: absolute;
    right: 0;
    bottom: 4px;
    .eyeOpened {
      display: block;
    }
    .eyeClosed {
      display: none;
    }
    &.closed {
      .eyeOpened {
        display: none;
      }
      .eyeClosed {
        display: block;
      }
    }
    &:hover{
      cursor: pointer;
    }
  }
}