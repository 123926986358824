.step {
  &sArea {
    display: flex;
    margin-top: 37px;
  }
  &Container {
    position: relative;
    &:first-child {
      .progressbarContainer {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    &:last-child {
      .progressbarContainer {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
  &Title {
    color: var(--gray-button-color);
    position: relative;
    background-color: var(--most-lite);
    position: relative;
    &.active {
      color: var(--dark-button-color);
    }
    &:hover {
      cursor: pointer;
    }
    .text {
      width: max-content;
      font-weight: 300;
      font-size: 14px;
      line-height: 36px;
      padding-bottom: 12px;
      position: relative;
      background-color: var(--most-lite);
      &::before,
      &::after {
        content: '';
        height: 12px;
        width: 12px;
        background-color: var(--dark-button-color);
        border-radius: 12px;
        position: absolute;
        left: calc(50% - 6px);
        bottom: 0;
        z-index: 1000;
      }
      &::after{
        background-color: var(--most-lite);
        height: 7px;
        width: 7px;
        border-radius: 9px;
        bottom: 2.5px;
        left: calc(50% - 3.5px);
      }
    }
  }
}
.progressbar {
  &Container {
    background-color: var(--gray-for-back);
    height: 3px;
    margin-left: 1px;
    overflow: hidden;
    width: calc(100% - 50px);
    bottom: 4.5px;
    position: absolute;
    z-index: 999;
    left: 50px;
    .progress {
      // top: calc(100% + 3px);
      height: 100%;
      background-color: var(--light-blue-color);
    }
  }
}

@media (max-width: 1366px) {
  .step {
    &sArea {
      margin-top: 44px;
    }
  }
}
