header{
    background-color: transparent !important;
    padding-inline: 0 !important;
    // margin-top: 29px;
    margin-bottom: 20px;

    .Container{
        flex-direction: row !important;
        justify-content: flex-end;
        margin-top: 0px;
    }

    .isFilled {
        background-color: var(--usual-button-color);
        color: var(--backlog) !important;
        padding: 6px 16px;
        font-weight: 300;
        transition: color 0.3s, background-color 0.3s;
        &:hover {
          color: var(--backlog) !important;
          background-color: var(--usual-button-hover-color);
          border-color: var(--usual-button-hover-color);
        }
      }
}

.transparentButton{
  background-color: transparent;
  border: 1px solid var(--usual-button-color);
  color: var(--usual-button-color) !important;
  transition: border-color 0.3s, color 0.3s;
  font-weight: 400;
  transition: background-color 0.3s, color 0.3s;
  padding: 8px 32px;
  cursor: pointer;
  &:hover, &:focus{
    background-color: transparent;
    border: 1px solid var(--usual-button-hover-color);
    color: var(--usual-button-hover-color) !important;
  }
}

.feedbaclFormContainer{
  padding: 32px !important;
}

.feedbackFormTitle {
  padding-top: 36px !important;
  text-align: center !important;
  // padding-bottom: 40px !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: var(--color-theme-text-color) !important;
}