.container {
    margin-left: 376px;
    margin-right: 224.5px;
    flex-direction: column;
    margin-top: 48px;
    // margin-top: 0;
  }

  .articleConatiner{
    margin-top: 40px;
  }

  @media (max-width: 1800px) {
    .container {
      margin-left: 376px;
      margin-right: 70px;
    }
  }
  
  @media (max-width: 1366px) {
    .container {
      margin-left: 376px;
      margin-right: 40px;
    }
  }

