.horizontalMenu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  width: max-content;
  height: max-content;
  margin-top: auto;
  li {
    img {
      // height: 40px;
      border-radius: 40px;
      min-height: 36px;
      min-width: 36px;
      height: auto;
      width: auto;
      max-width: 36px;
      max-height: 36px;
      object-fit: cover;
    }
  }
}
.userIcon {
  min-height: 38px;
  min-width: 38px;
  height: auto;
  width: auto;
  max-width: 38px;
  max-height: 38px;
  object-fit: cover;
}
.menuListItem {
  margin-left: 16px;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
}

.userButtonConatiner {
  position: relative;
  height: 38px;
  width: 38px;
  border-radius: 38px;
  text-align: center;
  cursor: pointer;
  // overflow: hidden;
  .userButtonInternalContainer{
    border-radius: 38px;
  }
}
