.authomaticData {
  display: flex;
  width: max-content;
  flex-direction: row !important;
  margin-top: 15px;
  justify-content: flex-start;
}

.avatar {
  height: 48px;
  width: 48px;
  text-align: center;
  border-radius: 48px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  img,
  svg {
    &.avatarImg {
      min-height: 48px;
      min-width: 48px;
    }
  }
}

.textData {
  padding-left: 36px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  .avatarName {
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    color: var(--dark-black);
    margin-bottom: 4px;
  }
  .date {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--signs-gray);
  }
}
