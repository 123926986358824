.submenu {
    height: max-content;
    transition: max-height 0.3s;
    top: 0px;
    background-color: var(--dark-element-hover);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: hidden;
    left: 100%;
    &.startLvl{
      left: 0;
      top: 60px;
    }
    &__icon {
      position: relative;
      &:before,
      &:after {
        content: '';
        position: absolute;
        border: 10px solid transparent;
        border-left-color: var(--most-lite);
      }
    }
    &__item{
      font-weight:400;
      line-height:21px;
      color: var(--most-lite);
      padding: 8px 16px;
      cursor: pointer;
      &:hover{
        background-color: var(--semi-darlk-element-hover);
      }
    }
  }
.listItem{
    position: relative;
}