.editArtivleContainer {
  position: relative;
  margin-top: 0;
}
.title {
  padding-top: 27px;
}
.invisible {
  display: none;
}

.formConatiner {
  margin: 24px 0px;
}
.collapseContainer {
  position: relative;
  [role='collapse-title'] {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    padding-top: 16px;
    color: var(--light-blue-color);
    // width: max-content;
    cursor: pointer;
    span {
      position: relative;
      padding-left: 47px;
      &::before {
        content: '+';
        position: absolute;
        left: 19px;
        height: 17.8px;
        width: 17.8px;
        border-radius: 18px;
        background-color: var(--base-button-background);
        color: var(--light-blue-color);
        border: 1px solid var(--light-blue-color);
        line-height: 17.8px;
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        transition: transform 0.3s;
        vertical-align: middle;
      }
    }
  }

  &[collapsed='true'] {
    [role='collapse-title'] {
      span {
        &::before {
          transform: rotate(45deg);
        }
      }
    }
  }
  &[collapsed='false'] {
    .deleteBtn {
      display: none;
    }
  }
}

.deleteBtn {
  position: absolute;
  right: 0px;
  top: 14px;
  transition: filter 0.2s;
  filter: brightness(0);
  svg {
    color: var(--light-blue-color);
  }
  &:hover {
    cursor: pointer;
    filter: brightness(1);
  }
}

.error {
  margin-top: 12px;
  color: var(--error-color);
  margin-bottom: 12px;
}
.addCategorieButton {
  margin-bottom: 12px;
  margin-top: 24px;
}

.selecorField {
  padding: 20px 0px;
}

.contentRow {
  padding-bottom: 48px;
  width: 100%;
}

.sectionTitle {
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
  // text-transform: uppercase;
  color: var(--main-color);
  padding-bottom: 16px;
  h2 {
    font-size: 20px;
    line-height: 28px;
  }
}

.articlePreviewContainer {
  padding-right: 0;
  padding-left: 0;
  margin-top: 20px;
}

.imagePreview {
  height: 88px;
  width: 179px;
  margin-top: 16px;
  margin-bottom: 32px;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  transition: height 0.2s;

  .cross {
    height: 11px;
    width: 11px;
    position: absolute;
    top: 1px;
    right: 1px;
    transform: rotate(45deg);
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      content: '';
      background-color: white;
    }
    &::before {
      top: 50%;
      left: 0;
      right: 0;
      height: 1px;
    }
    &::after {
      left: 50%;
      top: 0;
      bottom: 0;
      width: 1px;
    }
  }
}

.resultContainer {
  padding-top: 120px;
  padding-bottom: 30px;
  .imageBlock {
    height: auto;
    padding-bottom: 0px;
    text-align: center;
    img {
      height: auto;
      max-width: 69px;
    }
  }
  .textBalock {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }
}

.borderHint {
  margin-top: 30px;
  margin-bottom: 30px;
}

.draftSign {
  position: absolute;
  right: 70px;
  height: 59px;
  padding-top: 27px;
  line-height: 32px;
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
  color: var(--light-blue-color);
  a {
    color: var(--light-blue-color);
    text-decoration: underline;
    &:hover {
      color: var(--hover-color);
      text-decoration: underline;
    }
  }
}
.draftMenu {
  height: 0px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  width: max-content;
  z-index: 1003;
  // max-width: 110px;
  background-color: var(--backlog);
  color: var(--main-color);
  right: 0px;
  top: 20px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  transition: top 0.3s, opacity 0.3s;
  &Container {
    position: absolute;
    right: 25px;
    top: 33px;
    &.active {
      .draftMenu {
        opacity: 1;
        top: 40px;
        height: auto;
      }
      .draftMenuButton {
        background-color: var(--main-color);
      }
    }
  }
  &Button {
    width: 30px;
    background-color: var(--gray-for-back);
    border-radius: 30px;
    height: 20px;
    line-height: 8px;
    font-size: 22px;
    letter-spacing: 1px;
    color: var(--backlog);
    text-align: center;
    padding-left: 2px;
    transition: background-color 0.3s;
    cursor: pointer;
    &:hover,
    &focused,
    &.active {
      background-color: var(--main-color);
    }
  }
  &Item {
    padding: 6px 24px;
    font-size: 16px;
    line-height: 40px;
    &:hover,
    &:active,
    &:focus {
      background-color: var(--not-acsent-color);
      cursor: pointer;
    }
  }
}
.formButton {
  background-color: transparent;
  border: 1px solid var(--light-blue-color);
  color: var(--light-blue-color);

  &:hover {
    background-color: var(--light-blue-color);
    color: var(--backlog);
    cursor: pointer;
  }
  &.notActive {
    border-color: var(--gray-for-back);
    color: var(--gray-for-back);
    &:hover {
      border-color: var(--gray-for-back);
      color: var(--gray-for-back);
    }
  }
}
.linksTextArea {
  textarea {
    min-height: 0;
    height: 145px;
  }
}
.slide {
  margin: 5px;
  margin-top: 70px;
  &NoMargin {
    margin-top: 24px;
  }
  &.bigMargin{
    margin-top: 136px;
  }
}

.fileAddButton {
  background-color: transparent;
  border: 1px solid var(--light-blue-color);
  color: var(--light-blue-color);
  transition: color 0.3s, background-color 0.3s;
  &:hover {
    background-color: var(--light-blue-color);
    color: var(--backlog);
    cursor: pointer;
    border: 1px solid var(--light-blue-color);
  }
  &:active,
  &:focus {
    color: var(--backlog);
    border: 1px solid var(--light-blue-color);
  }
}
.linkSection {
  .linkItem {
    display: block;
    &.deletedLink {
      visibility: hidden;
      height: 0;
      overflow: hidden;
    }
  }
}

.notClickable {
  &:hover {
    cursor: not-allowed;
  }
}

.zoomDropDownList {
  position: relative;
}

.categorieForm {
  padding-left: 20px;
}

.descriptioContainer {
  margin-top: 0;
}

.inputAW {
  transition: border-color 0.2s;
  border-bottom: 2px solid var(--gray-button-color);
  font-size: 14px;
  line-height: 24px;
  padding: 0;
  padding-bottom: 24px;
  font-size: 48px;
  line-height: 64px;
  font-weight: 600;
  &:hover,
  &:active,
  &:focus {
    border-bottom: 2px solid var(--dark-button-color);
  }
  &::placeholder {
    font-weight: 600;
  }
}

.textareAW {
  padding: 24px;
  font-size: 14px;
  line-height: 24px;
  background-color: var(--light-gray-button-back);
  border: 2px solid var(--light-gray-button-back);
  color: var(--signs-gray);
  font-weight: 300;
  margin-top: 0px;
  &:focus,
  &:active {
    background-color: transparent;
    color: var(--most-dark);
    border-color: var(--dark-button-color);
  }
}

.imageLoader {
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s, filter 0.3s;
  img {
    max-height: 419px;
    max-width: 100%;
    width: auto;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .cross {
    height: 16px;
    width: 16px;
    position: absolute;
    top: 1px;
    right: 1px;
    transform: rotate(45deg);
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      content: '';
      background-color: var(--main-color);
    }
    &::before {
      top: 7.5px;
      left: 0;
      right: 0;
      height: 1.5px;
    }
    &::after {
      left: 7.5px;
      top: 0;
      bottom: 0;
      width: 1.5px;
    }
  }
  &:hover {
    filter: brightness(0.98);
    transform: scale(0.99);
  }
}
