.submenu {
  position: relative;
  &__item {
    font-weight: 400;
    line-height: 21px;
    font-size: 14px;
    color: var(--most-lite);
    cursor: pointer;
    position: relative;

    .icon {
      position: absolute;
      height: 100%;
      width: 10px;
      top: 0;
      bottom: 0;
      right: 10px;
      height: 20px;
      width: 20px;
      margin-top: auto;
      margin-bottom: auto;
      transition: transform 0.3s;
      transform: rotate(0deg);
      &.opened{
        transform: rotate(-90deg);
      }
    }
  }
}
.link {
  display: block;
  padding: 8px 30px;
  font-size: 14px;
  line-height: 21px;
  color: var(--most-lite) !important;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: var(--most-lite) !important;
    background-color: var(--menu-hover-button);
  }
  
}
.searchDescription{
  padding: 0 40px;
}