.ql-editor {
  white-space: normal !important;
  width: auto;
}
.ql-snow {
  &.ql-toolbar {
    border: none;
    font-size: 16px;
    line-height: 32px;
    padding: 0px;
    text-align: left;
    button {
      height: 32px;
      width: 32px;
      svg {
        height: 16px;
      }
    }
    .ql-formats {
      line-height: 32px;
      height: 32px;
    }
  }
  &.ql-container {
    width: 100%;
    min-height: 100px;
    border: none;
    .ql-editor {
      padding: 0px;
      padding-top: 16px;
      border: none;
      font-size: 16px;
      &.ql-blank {
        &::before {
          // content: '';
          font-style: normal;
          color: var(--gray-for-back);
          left: 0;
        }
      }
      p,
      h2,
      h3,
      li {
        width: max-content;
        min-width: 1px;
        max-width: 100%;
        background-color: var(--not-acsent-color);
        img {
          max-width: 100%;
        }
      }
      p {
        word-break: normal;
        min-height: 16px;
      }
    }
  }

  strong,
  u,
  s {
    font-style: inherit;
  }
  .ql-picker {
    height: 32px;
    .ql-picker-label {
      position: relative;
      border-color: var(--opacity-text);
      border-radius: 2px;
      &[data-label]:not([data-label='']) {
        &::before {
          position: absolute;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
          height: max-content;
        }
      }
    }
    &:hover {
      .ql-picker-label {
        border-color: var(--success-text);
        color: var(--success-text);
        svg {
          color: var(--success-text);
        }
      }
    }
    &.ql-expanded {
      .ql-picker-options {
        // margin-top: 7px;
        background-color: var(--backlog);
        z-index: 10000000;
      }
    }
  }
}

.text-editor_usualT-text-mode_article-wizard {
  padding-top: 40px;
  font-size: 16px;
  line-height: 1.42;
  cursor: default;
  .text-editor-container_usualT-text-mode_article-wizard {
    width: max-content;
    height: max-content;
    max-width: 100%;
    cursor: pointer;
    img {
      max-width: 100%;
    }
    &:hover {
      background-color: var(--not-acsent-color);
    }
  }
}
