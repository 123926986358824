.imageContainer {
  height: 296px;
//   background-color: var(--gray-button-color);
  &.maxSize{
    height: 211px;
    width: 377px;
    margin: 24px;
    margin-left: 0;
  }
  .image {
    height: 100%;
    width: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
