.container {
  background-color: var(--most-lite);
  max-width: 512px;
  border-radius: 12px;
  border: 1px solid var(--gray-button-color);
  overflow: hidden;
  position: relative;
  &.maxSize{
    max-width: none;
    display: flex;
    justify-content: space-between;
  }
  &.darkMode{
    background-color: var(--light-gray-color);
    // border: 1px solid var(--gray-button-color);
    &:focus, &:active, &:hover{
      border: none;
    }
  } 
  &:hover{
    border: 1px solid var(--dark-button-color);
  }
  &:focus, &:active{
    background-color: var(--light-gray-color);
    border: 1px solid var(--light-gray-color);
  }
}
.link{
  cursor: pointer;
}

.tools{
  &Container{
    position: absolute;
    display: flex;
    flex-direction: flex-end;
    top: 20px;
    right: 20px;
  }

  &Item{
   margin-left: 12px;
   filter: brightness(0.1);
   opacity: 0.9;
   transition: filter 0.2s, opacity 0.2s;
   &:hover, &:active{
    cursor: pointer;
    filter: brightness(1);
    opacity: 1;
   };
   img{
    height: 20px;
    width: 20px;
   }
  }

}
