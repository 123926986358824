.editCommentContainer {
  position: relative;
  .editCommentForm {
    .editCommentField {
      height: 80px;
      min-height: 80px;
      border-radius: 2px;
      padding: 16px;
    }
    .hiddenSubmit {
      display: none;
    }
  }
}
.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  .formButton {
    padding-left: 4px;
    margin-left: 24px;
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
    img {
      vertical-align: middle;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.fieldError {
  color: var(--error-color);
  font-size: 14px;
  font-weight: 300;
  bottom: 0px;
  position: absolute;
}
