.AsideMenu {
  position: fixed;
  left: -464px;
  top: 0;
  height: 100vh;
  overflow-y: scroll;
  background-color: var(--backlog);
  width: 464px;
  padding: 64px 0px;
  z-index: 99999999;
  transition: left 0.3s;
  &.visible {
    left: 0px;
  }
  &Container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--ukhd-background-gray);
    height: 100vh;
    width: 100vw;
    z-index: 9999999;
    margin: 0px;
    opacity: 0;
    transition: opacity 0.5s;
    display: block;
    &.visible {
      opacity: 1;
    }
    &.notVisible {
      display: none;
    }
  }
  &Cross {
    position: absolute;
    height: 37px;
    width: 37px;
    right: 12px;
    top: 12px;
    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 17px;
      width: 1px;
      transform: rotate(45deg);
      background-color: var(--main-color);
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }
    &:after {
      transform: rotate(-45deg);
    }
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }
}

.ExumpleContainer {
  padding: 32px 64px;
  &.active{
    background-color: var(--not-acsent-color);
  }
  &:hover{
    cursor: pointer;
    background-color: var(--light-hover-cover);
  }
  &Picture {
    margin-bottom: 56px;
    padding: 0px 40px;
    .smallImage{
      &Title{
      font-size: 10px !important;
      line-height: 12px !important;
      }
      &Picture{
        margin-bottom: 10px;
      }
    }
  }
  &Sign{
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    padding-bottom: 10px;;
    border-bottom: 1px solid var(--opacity-text);
    .number{
      padding: 4px 16px;
      color: var(--backlog);
      background-color: var(--opacity-text);
      border-radius: 30px;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      height: max-content;
    }
    .text{
      font-size: 15px;
      font-weight: 300;
      margin-left: 6px;
      text-align: justify;
    }
  }
}
