.textContainer {
    // background-color: var(--most-lite);
    max-width: 100%;
    padding: 24px;
    &.darkMode {
      background-color: var(--light-gray-color);
    }
    .authorContainer {
      display: flex;
      justify-content: flex-start;
      .autorImageContainer {
        height: 32px;
        width: 32px;
        border-radius: 32px;
        margin-right: 10px;
        img {
          min-height: 32px;
          max-height: 32px;
          min-width: 32px;
          max-width: 32px;
          height: auto;
          width: auto;
          object-fit: cover;
          border-radius: 32px;
          //add settings as in avatar on top
        }
      }
      .autorName {
        font-size: 14px;
        line-height: 20px;
        vertical-align: middle;
        padding: 6px 0;
        color: var(--gray-signs-text);
        font-weight: 400;
      }
    }
    .titleContainer {
      padding: 12px 0;
      h3 {
        font-size: 24px;
        line-height: 36px;
        color: var(--color-theme-text-color);
        font-weight: 500;
      }
    }
    .previewText {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      white-space: pre-line;
      overflow-y: hidden;
    }
    .actions {
      padding-top: 40px;
      display: flex;
      justify-content: space-between;
      .data {
        font-size: 14px;
        line-height: 30px;
        padding: 5px 0;
        padding-right: 33px;
      }
      .tagsContaioner {
        display: flex;
        justify-content: space-between;
        .buttonItem {
          margin-right: 20px;
        }
      }
      .saveToFavContainer {
        padding: 5px 0;
        margin-left: 33px;
        min-width: 24px;
        min-height: 24px;
      }
    }
  }