.feedbackForm {
  input, textarea{
    font-family: "Poppins";
  }
  textarea {
    margin-top: 24px;
    border-radius: 4px;
  }
  .error {
    position: absolute;
  }
  .submitBtn {
    margin-top: 24px;
  }
}

.invisible {
  display: none;
}

.recipientDescription {
  &Area {
    margin-top: 61px;
    padding: 24px;
    background-color: var(--not-acsent-color);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-bottom: 41px;
    font-size: 14px;
    line-height: 20px;
  }
}
.descriptioContainer {
  margin-top: 0 !important;
  margin-left: 16px;
}
.rolesContainer {
  margin-right: 16px;
}
.datList {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-transform: none;
  width: 100%;
  z-index: 1000;
}

@media (max-width: 1400px) {
  input,
  textarea {
    font-size: 14px;
  }
  .input_error {
    font-size: 14px;
  }
}

.marginFields {
  margin-top: 47px;
}

.selectRecipientDescription {
  margin-top: 0px;
}
.bitMarginLeft {
  margin-right: 16px;
  margin-bottom: 40px;
}
.bitMarginRight {
  margin-left: 16px;
  margin-bottom: 40px;
}
