.backgroundShade {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--shade-color);
  z-index: 1001;

  &.noShade{
    position: absolute;
    background-color: transparent;
    height: 0;
    width: 0;
    .popupConainer{
      position: fixed;
    }
  }
  .popupConainer {
    position: absolute;
    height: max-content;
    width: max-content;
    max-width: 637px;
    min-width: 529px;
    background-color: var(--backlog);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 24px;
    border-radius: 8px;
    .cross {
      height: 24px;
      width: 24px;
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 2.5px;
        right: 11.5px;
        height: 17px;
        width: 1px;
        background-color: var(--main-color);
      }
      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
      &:hover{
        background-color: var(--not-acsent-color);
      }
    }
    .titles {
      text-align: left;
      &.title {
        font-weight: 500;
        font-size: 20px;
        line-height: 48px;
        color: var(--most-dark);
        text-align: left;
        // padding: 8px 0;
      }
      &.subtitle {
        font-weight: 400;
        text-align: left;
        font-size: 16px;
        line-height: 64px;
      }
    }
    .message {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      // padding-top: 65px;
      textarea {
        height: 131px;
        min-height: 131px;
      }
      a{
        cursor: pointer;
        text-decoration: underline;
        color: var(--light-blue-color);
        &:hover{
          
        }
      }
    }
    .buttonsSection {
      padding-top: 32px;
      justify-content: flex-end;
      display: flex;
      .popup {
        &Button {
          margin-right: 6px;
          font-weight: 500;
          font-size: 16px;
          text-transform: none;
          line-height: 24px;
        }
        &Link {
          background-color: transparent;
          border-color: transparent;
          color: var(--main-color);
          &:hover {
            background-color: var(--not-acsent-color);
          }
          &:focus, &:active{
            background-color: var(--light-hover-color);
            color: var(--light-blue-color);
          }
        }
      }
      .buttons{
        margin-left: 24px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .backgroundShade {
    .popupConainer {
      max-width: 489px;
      padding: 30px;
      .titles {
        &.title {
          font-size: 24px;
          line-height: 36px;
        }
        &.subtitle {
          font-size: 14px;
          line-height: 35px;
        }
      }
      .message{
        padding-top: 45px;
        textarea{
          margin-top: 45px;
        }
      }
    }
  }
}
