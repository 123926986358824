.input-error {
  margin: 8px 0;
  color: var(--error-color);
  font-size: 14px;
  line-height: 20px;
  &.seccess {
    color: var(--success-text);
  }
}

.sub-titles {
  color: var(--medium-blue-color);
  font-weight: 600;
  // text-transform: uppercase;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: keep-all break-all;
  line-break: loose normal strict;
  &.sub-titles-lv1 {
    font-size: 24px;
    line-height: 35px;
    padding-bottom: 20px;
  }
  &.sub-titles-lv2 {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 8px;
  }
}
.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: var(--main-color);
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: keep-all break-all;
  line-break: loose normal strict;
  &.sub-description {
    color: var(--gray-for-back);
    margin-bottom: 11px;
    // position: absolute;
  }
}

.ant-typography {
  letter-spacing: 0.5px;
  padding: 8px 5px;
}

.custom-rows {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.has-nested-images {
  display: flex;
  justify-content: space-between;
}

.ql-edito {
  height: 409px;
}

.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 10px !important;
}

.ql-editor ul {
  padding-left: 0 !important;
}

.error {
  margin-top: 12px;
  color: var(--error-color);
  margin-bottom: 12px;
  &.success {
    color: var(--success-text);
  }
}

.ant-tabs-ink-bar {
  background: var(--light-blue-color) !important;
}

img,
svg {
  &.avatar {
    min-height: 130px;
    min-width: 130px;
    height: auto;
    width: auto;
    object-fit: cover;
  }
}

.cross {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 18px;
  width: 18px;
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    width: 1.5px;
    height: 100%;
    position: absolute;
    background-color: var(--dark-black);
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.BlockImageContainer {
  display: block;
  .FullSizeItems {
    text-align: left;
    font-size: 16px;
    line-height: 32px;
    font-weight: 300;
    text-align: center;
    img {
      // width: auto !important;
      max-width: 100% !important;
    }
  }
}
.FlexImageContainer {
  display: flex;
  justify-content: space-between;
  .HalfSizeItems {
    text-align: left;
    font-size: 16px;
    line-height: 32px;
    font-weight: 300;
    width: 75%;
    text-align: center;
    img {
      width: auto !important;
      max-width: calc(100% - 9px) !important;
    }
    span {
      display: block;
    }
    &:last-child {
      text-align: left;
    }
    &:last-child {
      width: calc(25% - 32px);
      margin-left: 32px;
    }
  }
}
.invisibleTitleImageContainer {
  display: none;
}

.errorField {
  color: var(--error-color);
  font-size: 16px;
  line-height: 30px;
  padding-top: 15px;
}

.image-mediafile {
  padding: 32px 0;
}

.fileListSection {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow-x: auto;
  min-height: 132px;
  padding-top: 48px;
  padding-bottom: 22px;
  .invisbleFilesAddForm {
    display: none;
  }
  .fileItem {
    height: 123px;
    width: 187px;
    min-width: 187px;
    border-radius: 2px;
    border: 1px solid var(--opacity-text);
    margin-right: 22px;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    &Title {
      background-color: var(--not-acsent-color);
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 4px 0px;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      z-index: 99997;
      height: max-content;
      // opacity: 1;
      transition: none;
      span {
        font-size: 12px;
      }
    }
    .hoverBlock {
      opacity: 0;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: var(--background-dark-gray);
      transition: opacity 0.3s;
      z-index: 99998;
      .fileItemTitle {
        color: var(--light-gray-color);
        top: 0;
        z-index: 99999;
        background-color: transparent;
      }
    }
    &:hover {
      .hoverBlock {
        opacity: 1;
        .fileItemTitle {
          opacity: 1;
        }
      }
      .fileItemTitle {
        opacity: 0;
      }
    }
    .fileItemButtonsContainer {
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      bottom: 14px;
      left: 0px;
      .fileButtonItem {
        padding: 6px 13px;
        text-align: center;
        background-color: var(--light-hover-color);
        border-radius: 2px;
        margin-right: 8px;
        img {
          height: 13px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.fileSectionMenu {
  border-bottom: 1px solid var(--opacity-text);
  padding-bottom: 9px;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  .fileListSection_title {
    font-size: 20px;
    font-weight: 400;
    color: var(--main-color);
    flex-grow: 1;
    line-height: 28px;
    padding-bottom: 24px;
    color: var(--color-theme-text-color);
  }
}
.fileListSection {
  &_TitleContainer {
    display: block;
    height: 100%;
    // position: relative;
  }
  .fileListSection_Item {
    display: block;
    height: 123px;
    width: 187px;
    min-width: 187px;
    border-radius: 2px;
    background-color: var(--light-gray-button-back);
    border: 1px solid var(--gray-button-color);
    margin-right: 12px;
    position: relative;
    &Title {
      background-color: var(--not-acsent-color);
      position: absolute;
      left: 0;
      bottom: 0px;
      padding: 4px 0px;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      z-index: 99997;
      height: max-content;
      transition: none;
      text-decoration: none;
      span {
        font-size: 12px;
        text-decoration: none;
        line-height: 18px;
        font-weight: 500;
      }
    }
    .fileListSection_Item_hoverBlock {
      opacity: 0;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: var(--background-dark-gray);
      transition: opacity 0.3s;
      z-index: 99998;

      .fileListSection_ItemTitle {
        color: var(--backlog);
        top: 0;
        z-index: 99999;
        background-color: transparent;
      }
      &:hover {
        .fileListSection_Item_hoverBlock {
          opacity: 1;
          .fileItemTitle {
            opacity: 1;
          }
        }
        .fileItemTitle {
          opacity: 0;
        }
      }
    }
    &:hover {
      .fileListSection_Item_hoverBlock {
        opacity: 1;
      }
    }
  }
}
.fileItemButtonsContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 14px;
  left: 0px;
  .fileButtonItem {
    text-align: center;
    background-color: var(--light-hover-color);
    border-radius: 2px;
    margin-right: 8px;
    a,
    .imageContainer {
      padding: 6px 13px;
      height: 100%;
      width: 100%;
    }
    img {
      height: 13px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

a {
  color: var(--links-color) !important;
  &:hover {
    color: var(--links-hover-color) !important;
  }
}

@media (max-width: var(--lg)) {
  .sub-titles {
    &.sub-titles-lv2 {
      font-size: 20px;
      line-height: 28px;
    }
  }
  .description {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: var(--pre-lg)) {
  .sub-titles {
    &.sub-titles-lv2 {
      font-size: 20px;
      line-height: 28px;
    }
  }
  .description {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: var(--middle)) {
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .fileListSection {
    flex-wrap: wrap;
    .fileListSection_Item {
      margin-bottom: 22px;
    }
  }
  .print-container {
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  .content-block {
    iframe {
      display: none;
    }
  }
  .preview-height {
    height: auto !important;
  }
}

.resultingPart {
  color: var(--dark-button-tap);
}
